import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="form"
export default class extends Controller {
  static targets = [ "focus" ]

  connect() {
    if(this.hasFocusTarget){
      this.focus(this.focusTarget);
    }

    this.submit = debounce(this.submit.bind(this), 200);
  }

  submit(event) {
    this.element.requestSubmit();
  }

  focus(element) {
    let end = element.value.length

    element.setSelectionRange(end, end);
    element.focus();
  }

  scroll(e) {
    e.target.scrollIntoView();
  }

  redirect(event) {
    if (event.detail.success) {
      event.preventDefault();

      const fetchResponse = event.detail.fetchResponse

      history.pushState(
        { turbo_frame_history: true },
        "",
        fetchResponse.response.url
      )

      Turbo.visit(fetchResponse.response.url)
    }
  }
}
