import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    setTimeout(() => this.hide(), 4000);
  }

  hide() {
    this.element.classList.add('flash--hide');
    
    setTimeout( () => {
      this.element.remove();
    }, 1000);
  }
}
