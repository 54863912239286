import { Controller } from "@hotwired/stimulus"

import LicensePlate from 'license-plate';

// Connects to data-controller="licenseplate"
export default class extends Controller {
  connect() {
    
  }

  update() {
    let plate = new LicensePlate(this.element.value);

    if(plate.valid()) this.element.value = plate.pretty();
  }
}
