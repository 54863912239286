import { Controller } from "@hotwired/stimulus";
import { elementToSVG } from "dom-to-svg";
import debounce from "debounce";

// Connects to data-controller="avatar"
export default class extends Controller {
  static values = {
    parts: String,
  };

  static targets = ["preview", "parts", "part", "kind", "settings", "svg", "frame"];

  connect() {
    this.parts = JSON.parse(this.partsValue);

    this.changeColor = debounce(this.changeColor.bind(this), 100);
    this.updateSvg = debounce(this.updateSvg.bind(this), 500);

    this.kinds = {};
    this.colors = {};

    if (this.settingsTarget.value.indexOf("{") == 0) {
      let settings = JSON.parse(this.settingsTarget.value);

      this.kinds = settings.kinds;
      this.colors = settings.colors;
    } else {
      Object.keys(this.parts).forEach((part) => {
        this.colors[part] = this.parts[part].color;
        this.kinds[part] = "default";
      });
    }

    this.preview();
  }

  generateColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  randomize(event) {
    event.preventDefault();

    this.selectColor("frame", this.generateColor());
    this.selectColor("body", this.generateColor());

    Object.keys(this.parts).forEach((part) => {
      let kinds = Object.keys(this.parts[part].kinds);

      this.selectKind(part, kinds[Math.floor(Math.random() * kinds.length)]);
    });

    this.preview();
  }

  nextKind(event) {
    this.switchDirection(event, true);
  }

  prevKind(event) {
    this.switchDirection(event, false);
  }

  updateSettings() {
    this.settingsTarget.value = JSON.stringify({
      kinds: this.kinds,
      colors: this.colors,
    });
  }

  switchDirection(event, direction) {
    let part = event.target.closest("[data-avatar-target]").dataset.name;

    let kinds = Object.keys(this.parts[part].kinds);

    let index = kinds.indexOf(this.kinds[part]);

    if (index >= kinds.length) index = 0;

    if (direction) {
      index++;

      if (index >= kinds.length) index = 0;
    } else {
      index--;

      if (index < 0) index = kinds.length - 1;
    }

    this.selectKind(part, kinds[index]);

    this.preview();
  }

  selectKind(part, kind) {
    this.kinds[part] = kind;

    console.log(this.kinds);
  }

  selectColor(part, color) {
    document.querySelector(
      `[data-name="${part}"] .colorpicker`
    ).colorpicker.colorPicker.color.hexString = color;
    this.colors[part] = color;

    this.preview();
  }

  changeColor(e) {
    this.colors[e.target.closest("[data-name]").dataset.name] = e.target.value;
    this.preview();
  }

  preview() {
    this.previewTarget.innerHTML = "";
    this.previewTarget.style.backgroundColor = this.colors.frame;
    this.frameTarget.style.backgroundColor = this.colors.frame;

    ["body", "head", "hair", "mouth", "nose", "facial", "eyes"].forEach(
      (part) => {
        let svg = this.parts[part].kinds[this.kinds[part]];

        let element = document.createElement("div");
        element.innerHTML = svg;

        element
          .querySelector("svg")
          .setAttribute("width", this.previewTarget.offsetWidth + "px");
        element
          .querySelector("svg")
          .setAttribute("height", this.previewTarget.offsetHeight + "px");

        let color = this.colors[part];

        if (color) {
          let path = element.querySelector("svg #dye");
          if (path) path.setAttribute("fill", color);
        }

        // let element = document.createElement("img");
        // element.src = `data:image/svg+xml;base64,${btoa(elementSvg.innerHTML)}`;

        this.previewTarget.append(element);
      }
    );

    this.updateSettings();
    this.updateSvg();
  }

  updateSvg() {
    //this.previewTarget.style.width = '200px';
    let svgDocument = elementToSVG(this.previewTarget);
    //this.previewTarget.style.width = 'auto';

    this.svgTarget.value = svgDocument.querySelector("svg").outerHTML;
  }
}
