import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

var clickOutside = require("click-outside");
export default class extends Controller {
  static targets = ["modal", "frame", "sheet"];

  connect() {
    this.element[this.identifier] = this;

    document.addEventListener("turbo:before-fetch-request", (e) => {
      if (e.detail.fetchOptions.headers["Turbo-Frame"] == this.identifier) {
        this.open();
      }
    });


    document.addEventListener("turbo:frame-render", (e) => {
      if (e.target.id == this.identifier) {
        const event = new Event("modal:load");

        document.dispatchEvent(event);
      }
    });

    document.onkeydown = (e) => {
      if (e.key == 'Escape') this.close({ force: true });
    }

    // window.addEventListener("hashchange", () => {
    //   let element = document.createElement("a");

    //   if(!window.location.hash.includes('/')) return;

    //   element.href = window.location.hash.replace("#", "/");
    //   element.dataset.turboFrame = "modal";
    //   document.body.append(element);
    //   element.click();
    //   element.remove();
    // });
  }

  error() {
    //this.close({ force: true });

    this.frameTarget.innerHTML = `<div class="well well--md well--white" style="width: 300px"><div class="well__icon"><i class="fal fa-face-frown text-danger"></i></div><div class="well__body"><h6>Er is iets fout gegaan</h6><div class="small text-desc">We zijn op de hoogte gebracht van dit probleem.</div></div></div>`
  }

  close(e) {
    if (e.force || e.currentTarget == e.target) {
      if(!e.force) e.preventDefault();

      this.data.delete("open");

      if(this.hasSheetTarget) {
        if(this.sheetTarget.dataset.modalRefresh){
          window.turboScroll = document.scrollingElement.scrollTop;
          Turbo.visit(window.location)
        }
      }

      this.closeTimeout = setTimeout(() => {
        this.frameTarget.innerHTML = "";
      }, 500);
    }
  }

  open() {
    if(this.closeTimeout) clearTimeout(this.closeTimeout);

    this.eventListener = clickOutside(this.frameTarget, (e) => {
      this.close({force: true});

      this.eventListener();
    });


    if(this.data.get('open') != '1'){
      this.frameTarget.innerHTML = "";
    }

    this.data.set("open", "1");
  }
}
