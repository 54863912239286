import { Controller } from "@hotwired/stimulus";

import iro from "@jaames/iro";

// Connects to data-controller="colorpicker"
export default class extends Controller {
  static targets = ["picker", "input", "preview"];

  connect() {
    this.element[this.identifier] = this;

    this.colorPicker = new iro.ColorPicker(this.pickerTarget, { width: 120 });

    this.inputTarget.dataset.action += " input->colorpicker#update";
    this.inputTarget.autocomplete = "off";

    this.colorPicker.on("color:change", (color) => {
      this.inputTarget.value = color.hexString;
      this.previewTarget.style.backgroundColor = color.hexString;

      let event = new Event("change");
      this.inputTarget.dispatchEvent(event);
    });

    this.update();
  }

  update() {
    this.colorPicker.color.hexString = this.inputTarget.value;
  }
}
