import { Controller } from "@hotwired/stimulus"

import {birds, animateBirds} from "../libraries/birds.js";

// Connects to data-controller="birds"
export default class extends Controller {
  connect() {

    setTimeout( () => {
      birds(this.element);
      animateBirds();
    }, 1000);

  }

  
}