import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="support-action"
export default class extends Controller {
  static values = { url: String }

  connect() {
  }

  click(e) {
    //e.preventDefault();

    fetch(this.urlValue, { method: 'GET' })
  }
}
