import { Controller } from "@hotwired/stimulus";
import Cookies from 'js-cookie'

// Connects to data-controller="card"
export default class extends Controller {

  connect() {
    let cookie = Cookies.get(`card-${this.element.id}`)

    if(cookie) this.close();
  }

  close(e) {
    if(e){
      e.preventDefault();
  
      Cookies.set(`card-${this.element.id}`, 'closed')
    }

    this.element.classList.add('card--closed')
  }
}
