import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lines"
export default class extends Controller {

  colors = [
    "#FFE870",
    "#EFAA83",
    "#B89EF9",
    "#66D6FC",
    "#E9F1A1",
    "#5CD17D"
  ];

  connect() {
    this.createLines();

    this.durationMultiplier = (this.element.scrollWidth / 500);
    if(this.durationMultiplier < 2) this.durationMultiplier = 2;

    this.heightDivider = (this.element.scrollHeight / 600);

    if(this.heightDivider < 1.5) this.heightDivider = 1.5;
  }

  createLines = () => {
    let amount = Math.ceil(this.element.scrollHeight / 40);

    if(amount < 10) amount = 10;

    for (let i = 0; i < amount; i++) {
      let line = document.createElement("div");
      line.className = "line";

      line.addEventListener('animationend', () => {
        this.setProperties(line);
      })

      this.setProperties(line);

      this.element.appendChild(line);
    }
  }

  getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  setProperties = (el) => {
    let size = (Math.random() * 60) + 20;
    el.style.top = Math.random() * 100 + "%";
    el.style.left = 0;
    el.style.width = size + "px";
    el.style.height = (size / this.getRandomArbitrary(3,6)) / this.heightDivider + "px";
    el.style.opacity = this.getRandomArbitrary(0.6,1);
    if(parseInt(this.getRandomArbitrary(0, 5)) == 0)
      el.style.filter = "blur(" + this.getRandomArbitrary(0, 5) + "px)";
    el.style.animationDelay = Math.random() * 2 + "s";
    el.style.animationDuration = Math.random() * this.durationMultiplier + 2 + "s";
    el.style.backgroundColor = this.colors[Math.floor(Math.random() * this.colors.length)];
    //el.style.borderColor = color[Math.floor(Math.random() * color.length)];
    //el.style.borderWidth = '3px'//Math.random() * 10 + "px";

    el.classList.remove('line--animation');
    void el.offsetWidth;
    el.classList.add('line--animation');
  }
}